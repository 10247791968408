.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.id {
    font-weight: bolder;
}

.id::before {
    content: 'ID: ';
    opacity: 0.8;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
}

.token {
    font-size: 0.8em;
}

.footer {
    display: flex;
    flex-direction: column;
    font-size: 0.8em;
    align-items: start;
}