.userTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
}

.userTitle {
    overflow: clip;
}

.userId {
    font-weight: bolder;
}

.userId::before {
    content: 'ID: ';
    opacity: 0.8;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
}