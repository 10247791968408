.queryLine {
    display: flex;
}

.queryLine > *:nth-child(1) {
    flex: 0 1 20% !important;
}

.queryLine > *:nth-child(2) {
    flex: 0 1 3.5em !important;
}

.queryLine > *:nth-child(3) {
    flex: 0 1 70% !important;
}

.queryLine > *:nth-child(5) {
    flex: 0 1 10% !important;
}

.usersTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
}

.titleWithProgress {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.usersTitle {
    font-size: 1.2em;
    font-weight: bold;
}

.usersError {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.usersItem {
    border-radius: 0;
}

.dateLine {
    font-size: 0.7em;
    font-weight: bold;
    opacity: 0.7;
}

.modelLine {
    font-size: 0.7em;
    font-weight: bolder;
}