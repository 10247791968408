body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* I'm so sorry */
.list-group-item.active {
  background-color: rgba(13, 110, 253, 0.1) !important;
  border-color: rgba(13, 110, 253, 0.1) !important;
  color: black !important;
}
