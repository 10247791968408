.cardsList {
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
}

.card {
    min-width: 200px !important;
    flex-grow: 0.33;
    flex-shrink: 0;
    flex-basis: 0;
}

.cardDisabled {
    min-width: 200px !important;
    flex-grow: 0.33;
    flex-shrink: 0;
    flex-basis: 0;
}

.cardDisabled span {
    opacity: 0.5;
}

.noAccess {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.cardTitle {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    cursor: pointer;
}