.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.title {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.title > *:last-child {
    margin-top: 2px;
}

.actions {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: center;
}

.action {
    cursor: pointer;
}