.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    gap: 8px;
}

.icons {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-top: 4px;
}

.time {
    font-size: 0.8em;
    font-weight: bold;
}

.description {
    font-size: 0.7em;
}