.card {
    margin-top: 20%;
}

.row {
    height: 100vh;
    overflow-y: scroll;
}

.title {
    font-weight: 700;
    font-size: 1.6em;
    padding-bottom: 0.5em;
}

.label {
    font-size: 0.8em;
    font-weight: 600;
}

.group:not(:first-child) {
    margin-top: 0.5em;
}

.submitGroup {
    width: 100%;
    margin-top: 0.5em;
    display: flex;
    flex-direction: column;
}

.errorBlock {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4px;
}

.message {
    margin-bottom: 12px;
}