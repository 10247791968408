.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
}

.id {
    font-weight: bolder;
}

.id::before {
    content: 'ID: ';
    opacity: 0.8;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
}

.plan {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
    align-items: center;
}

.plan::before {
    content: 'Plan: ';
    opacity: 0.8;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
}

.interval {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
}

.date_info {
    font-size: 0.8em;
    font-weight: bold;
}